import React  from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Layout from '../../components/layout'
import Block from '../../components/block'

const WorkPage = () => {
    const data = useStaticQuery(graphql`
    query Work {
        strapiPageWork {
            Hero {
                Header
                Tagline
            }

            CallToAction {
                Title
                Tagline
                ButtonText
                ButtonLink
            }
        }
        allStrapiClient {
            nodes {
                id
                Name
                Description
                Tags
                Logo {
                  publicURL
                }
                Exclude
            }
        }
    }`)

    const page = data.strapiPageWork
    const clients = data.allStrapiClient.nodes

    const CallToAction = page.CallToAction && (
        <section className="call-to-action secondary-b">
          <div className="content extra column">
            <div className="header space-between">
              <div className="title">
                <h4>{ page.CallToAction.Title }</h4>
                <h3 dangerouslySetInnerHTML={{ __html: page.CallToAction.Tagline }} />
              </div>
    
              <Link to={ page.CallToAction.ButtonLink } className="button inverse mob-icon">
                <i className="fas fa-chevron-right" />
                <span>{ page.CallToAction.ButtonText }</span>
              </Link>
            </div>
          </div>
        </section>
      )

    return (
        <Layout colorTheme="secondary-d" bgColorTheme="bright">
            <main id="work">
                <section className="hero comp-pada bright">
                    <div className="content extra">
                        <div className="tagline column">
                            <h1>{ page.Hero.Header }</h1>
                            <p>{ page.Hero.Tagline }</p>
                        </div>
                    </div>
                </section>

                <section className="work comp-pad white">
                    <div className="content column">
                        <div className="grid col-2 mob-wrap">
                            { 
                                clients.slice(0, 2).map((client) => 
                                    <div>
                                        <div key={ client.id } className="product-logo" style={{ backgroundImage: `url(${client.Logo.publicURL})` }} />
                                        <div className="product row">
                                            <div className="info column">
                                                { client.Tags.split(",").map((t) => <div>{ t }</div>) }
                                            </div>
                                            <div className="excerpt">
                                            <p>{ client.Description }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                <section className="work white">
                    <div className="content column">
                        <Block modifiers="case-study">
                            <div className="header space-between">
                                <div className="title">
                                <h5 className="subtext">Ohio State University</h5>
                                <h3>Accelerated Study:<br /> Get good data to researchers now</h3>
                                </div><Link to="./work/osu" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>View case study</span></Link>
                            </div>
                            <img className="screenshot" src="../images/screenshot.jpg" />
                        </Block>
                    </div>
                </section>

                <section className="work white">
                    <div className="content column">
                        <div className="grid col-2 mob-wrap">
                            { 
                                clients.slice(2, 6).map((client) => 
                                    <div>
                                        <div key={ client.id } className="product-logo" style={{ backgroundImage: `url(${client.Logo.publicURL})` }} />
                                        <div className="product row">
                                            <div className="info column">
                                                { client.Tags.split(",").map((t) => <div>{ t }</div>) }
                                            </div>
                                            <div className="excerpt">
                                            <p>{ client.Description }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                <section className="work white">
                    <div className="content column">
                        <Block modifiers="case-study secondary-a">
                            <div className="header space-between">
                                <div className="title">
                                <h5 className="subtext">Surgere</h5>
                                <h3>Bringing order to a massive tag-ordering system</h3>
                                </div><Link to="./work/surgere" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>View case study</span></Link>
                            </div>
                            <img className="screenshot" src="../images/work/surgere/dashboard-browser.png" />
                        </Block>
                    </div>
                </section>

                <section className="work white">
                    <div className="content column">
                        <div className="grid col-2 mob-wrap">
                            { 
                                clients.slice(6, 10).map((client) => 
                                    <div>
                                        <div key={ client.id } className="product-logo" style={{ backgroundImage: `url(${client.Logo.publicURL})` }} />
                                        <div className="product row">
                                            <div className="info column">
                                                { client.Tags.split(",").map((t) => <div>{ t }</div>) }
                                            </div>
                                            <div className="excerpt">
                                            <p>{ client.Description }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                <section className="work white no-pad">
                    <div className="content column">
                        <Block modifiers="case-study secondary-c">
                            <div className="header space-between">
                                <div className="title">
                                <h5 className="subtext">Latex Construction</h5>
                                <h3>Tracking pipeline progress,<br /> saving time and cash</h3>
                                </div><Link to="./work/latex" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>View case study</span></Link>
                            </div>
                            <img className="screenshot" src="../../images/work/latex/mobile-map-report.png" width="100%" />
                        </Block>
                    </div>
                </section>

                <section className="work white">
                    <div className="content column">
                        <div className="grid col-2 mob-wrap">
                            { 
                                clients.slice(10).map((client) => 
                                    !client.Exclude && <div>
                                        <div key={ client.id } className="product-logo" style={{ backgroundImage: `url(${client.Logo.publicURL})` }} />
                                        <div className="product row">
                                            <div className="info column">
                                                { client.Tags && client.Tags.split(",").map((t) => <div>{ t }</div>) }
                                            </div>
                                            <div className="excerpt">
                                            <p>{ client.Description }</p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </section>

                { CallToAction }
            </main>
        </Layout>
    )
}

export default WorkPage;