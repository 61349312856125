import React from 'react'
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Block from '../../components/block'

const OSUPage = () => (
    <Layout colorTheme="white" bgColorTheme="secondary-b">
        <main id="work">
            <section className="hero secondary-b no-bottom-pad">
                <div className="content-break">
                    <div className="container" style={{ minHeight: 300 }}>
                        <div className="tagline column" style={{ marginTop: "2rem", marginBottom: "2rem"}}>
                            <h5 className="subtext">Ohio State University</h5>
                            <h1>Making it easy to find<br /> the correct data</h1>
                            <a className="button inverse hide-mobile icon"><i className="fas fa-chevron-down" /></a>
                        </div>
                        <div className="breakout" style={{ backgroundImage: "url(../../images/screenshot.jpg)", marginLeft: "4rem"  }} />
                    </div>
                </div>
            </section>

            <section className="work bright">
                <div className="content" style={{ maxWidth: 800 }}>
                    <div className="product breakout row">
                        <div className="info column">
                            <div>Strategy</div>
                            <div>UI Design</div>
                            <div>React</div>
                            <div>Mobile App</div>
                        </div>
                        <div className="excerpt">
                            <h4>The Premise</h4>
                            <p className="big">Ohio State is one of the foremost research universities, conducting innovative studies across a wide variety of fields (it's good at football too. And has Best Damn Band in The Land. We digress). A group of professors wanted to use artificial intelligence and machine-learning to help researchers find relevant data collections, and eventually expand the network to other universities. </p>
                            <p className="big">But first they needed a test version to gain funding. As respected members of their own fields, they knew a lot about the datasets they wanted to include. But finding a way to make it all work, and make it all work within their limited timeline, was something they would need help with.</p> 
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-bottom-pad">
                <div className="content extra column">
                    <div className="grid col-2 mob-wrap">
                        <div>
                            <div className="image-responsive ratio-3-4 round">
                                <div className="wrapper">
                                    <div className="image" style={{ backgroundImage: "url('../../images/work/osu/campus.jpg')" }} />
                                </div>
                            </div>
                        </div>

                        <Block modifiers="ratio-3-4 secondary-b-light center">
                            <img className="screenshot" src="../../images/work/osu/OSU-search.png" width="53%" />
                        </Block>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <h4 className="upper">The Solution</h4>
                            <p className="big">One of the coolest things about working in the development industry is teaming with clients that are looking to shake up their industries. We love it. But, as was the case with Ohio State University, we needed to find baby footsteps to move toward making a huge footprint. </p>
                        </div>
                        <div className="step">
                            <h5>First impression is everything</h5>
                            <p>We were impressed with their ambition. We were nervous with their proposed timeline for creating the initial beta versions of the Ohio State University Data Commons. They thought “smaller scope” meant including less data. The biggest thing on our mind wasn’t the stack of data; it was the machine learning algorithms. These would take time. </p>
                            <p>The project timeline was only a month. That didn’t give us, or anybody, time to program a machine learning algorithm that could perform the task well with any amount of data.</p>
                            <p>After brainstorming with the client, we decided to design and develop UI/UX in a month, but we couldn’t complete a machine-learning algorithm. That said, we could definitely create a clever lookalike to impress the university reps who would be approving the professors’ pitch. We could use autocomplete for the time being so it "looked right." </p>
                            <p>There was no way that machine learning could be avoided forever; the entire project depended on it. But this group of bold professors didn’t need the entire thing to work right now. They just needed something that gave the idea it was working on a small scale, so they could impress their bosses and get greenlit. Deadlines are real. Let’s build something in a month.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra">
                    <div className="image-responsive ratio-1-2 round">
                        <div className="wrapper">
                            <div className="image" style={{ backgroundImage: "url('../../images/work/osu/wireframe.jpg')" }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <p className="big">Another cool thing about working in development, especially an agency like Urbity? Bringing in new ideas from teammates who specialize in different aspects of the process. Number-crunchers and creatives working in the same room made a big difference with this project, as it has with so many others. </p>
                        </div>
                        <div className="step">
                            <h5>Big ideas happen in tight teams</h5>
                            <p>You can't fake some things. One of those was the privacy settings of datasets submitted to the app. Some researchers prefer open access, and others prefer requested access. We went through the client “wants” and whittled them down to “needs.” By identifying the most important parts of the project, we were able to strategize what should be done during the one-month sprint.</p>
                            <p>Having completed this strategy, we applied it to our designs as well. For example, submitted datasets would not be reviewed by AI during the beta phase. They would be reviewed by actual human beings. Therefore we designed a simple submission process that called for only essential information, limiting other additions. </p>
                            <p>We continued to design prototypes and bounce them off the client for feedback. Our design team worked with Ohio State’s development team and...it looked off. They’re data guys. They don’t think about fonts. Fortunately we had plenty of time to set everything right before the big day, but we learned something: If you can get a small team of designers and developers to work together, and not independently, your projects will turn out better.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">
                    <Block modifiers="image-cut primary">
                        <img className="screenshot" src="../../images/screenshot.jpg" width="100%" />
                    </Block>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <blockquote>"[Urbity’s] professionalism, talent and transparency were exceptional. They are experts in UI/UX (a world-class portfolio) and, equally important, they communicate well." <div className="by"><h5>Dr. Christopher Stewart</h5><h4>Ohio State University Department of Computer Science & Engineering</h4></div></blockquote>
                </div>
            </section>

            <section className="work white no-top-pad">
                <div className="content extra column">
                    <Block modifiers="image-cut primary">
                        <img className="screenshot" src="../../images/work/osu/OSU-Data-Commons-Add-New-Dataset.jpg" width="100%" />
                    </Block>
                </div>
            </section>

            <section className="values bright">
                <div className="content">
                    <div className="description">
                        <h2>What we learned</h2>
                        <p className="big">We didn’t know much about academic datasets, and we didn’t know much about pipeline construction. But we like working with diverse clients and picking up bits and pieces that can be applied to the next project.</p>
                    </div>
                    <div className="column card-view">
                        <div className="card">
                            <h5>Immediate > Ultimate</h5>
                            <p>The client wanted machine learning which was impossible in their timeline. But a clever mimic got the project approved for a longer-term development. </p>
                        </div>

                        <div className="card">
                            <h5>Plan for Humans</h5>
                            <p>Ultimately data entries would be sorted by AI. But for now, humans would sort. Figure out what's important, and keep an open mind for what can be done in the future.</p>
                        </div>

                        <div className="card">
                            <h5>Mingling Teams</h5>
                            <p>There are no walls between our developers and our designers, which makes communication quick. We don’t even need to stand up!</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-to-action secondary-b">
                <div className="content extra column">
                    <div className="header space-between">
                        <div className="title">
                            <h4>Surgere Case Study</h4>
                            <h3>Quick dev keeps you off<br/>the hook with big clients</h3>
                        </div>
                        <Link to="/work/surgere" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>Read more</span></Link>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
)

export default OSUPage;