import React from "react"
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Block from '../../components/block'

const LatexPage = () => (
    <Layout colorTheme="white" bgColorTheme="secondary-c">
        <main id="work">
            <section className="hero secondary-c no-bottom-pad" style={{ overflow: 'hidden'}}>
                <div className="content-break">
                    <div className="breakout row" style={{ marginRight: "4rem", alignItems: 'flex-end'  }}>
                        <div className="image-responsive">
                            <div className="wrapper" style={{ paddingTop: '70%' }}>
                                <div className="image" style={{ left: -4, backgroundImage: "url('../../images/work/latex/hero.png')" }} />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="tagline column" style={{ marginTop: "2rem", marginBottom: "2rem"}}>
                            <h5 className="subtext">Latex</h5>
                            <h1>Making new tech work for old pros</h1>
                            <a className="button inverse hide-mobile icon"><i className="fas fa-chevron-down" /></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work bright">
                <div className="content" style={{ maxWidth: 800 }}>
                    <div className="product breakout row">
                        <div className="info column">
                            <div>Strategy</div>
                            <div>UI Design</div>
                            <div>React</div>
                            <div>Mobile App</div>
                        </div>
                        <div className="excerpt">
                            <h4>The Premise</h4>
                            <p className="big">Latex Construction is one of the top pipeline construction companies. They know everything that causes headaches on complex projects, including data collection. For years, project managers had been gathering this information in notebooks. It was slow, and decision-makers wanted data quickly. Latex turned to Urbity to develop a network of apps for logging information on a cell phone, and a web app for analysis.</p>
                            <p className="big">Latex needed it done in six months because that’s when their next $100 million project was scheduled to begin. That meant Urbity needed to put its strategy helmet on, and help Latex determine the difference between features it wanted, and features it needed. The CEO was wary of using technology to replace a tried-and-true method, so the pressure was on to improve upon the old way.</p> 
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-bottom-pad">
                <div className="content extra">
                    <div className="image-responsive ratio-1-2 round">
                        <div className="wrapper">
                            <div className="image" style={{ backgroundImage: "url('../../images/work/latex/pipeline-project.jpg')" }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <h4 className="upper">The Solution</h4>
                            <p className="big">The client's needs are always important. But when you're talking about software development, the client isn't necessarily the user. In this case, the users would be industry veterans who liked their notebooks fine. The system wouldn't be effective if it wasn't easy.</p>
                        </div>
                        <div className="step">
                            <h5>Making it work for the audience</h5>
                            <p>The project was ambitious. A mobile app used to log every bit of data on the project. There were simple things like coordinates of a fix, but crew leaders also needed to confirm safety protocols were met for the purpose of creating compilation Progress Reports within the web app. Then there were safety reports, and quality reports. And then you had wifi problems in rural areas.</p>
                            <p>Technically speaking, this was no problem. But strategically speaking, this needed some intense thought. This app would be used by grizzled veterans of pipeline construction, guys who had been doing it the old-fashioned way for decades. We needed this app to make sense to them, or risk alienating them; just because you tell them to use a new app, you can’t assume they actually will.</p>
                            <p>On the other side of the pipeline, the Latex web app would bring in huge amounts of data for analysis by the corporate team. Our design team needed to find a way to make these huge troves of data simple to view from a common dashboard, and equally simple to sort and export for use.</p>
                            <p>This was a big project, with a big budget. It also had a big deadline hanging over it.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">
                    <div className="grid mob-wrap col-2">
                        <div>
                            <div className="image-responsive ratio-3-4 round">
                                <div className="wrapper">
                                    <div className="image" style={{ backgroundImage: "url('../../images/image-tes.jpg')" }} />
                                </div>
                            </div>
                        </div>

                        <Block modifiers="ratio-3-4 secondary-d">
                            <img className="screenshot" src="../../images/work/latex/latex-wireframes.png" width="100%" />
                        </Block>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <p className="big">The "problem" with good ideas is that clients are always looking to make them even better. That's all well and good, unless you're working on a tight deadline. Sometimes you've got to define the "now" good ideas and the "later" good ideas. </p>
                        </div>
                        <div className="step">
                            <h5>The task stack wobbles</h5>
                            <p>This was a big project with a big budget. The "simple" Progress Report was the sum of many tasks: We needed to create separate reports for all users in the field, which needed to accept manual data entry while ingesting geographic and weather information, and also connecting to the user’s phone camera. It all needed to go to the right place and be organized into a digestible report.</p>
                            <p>And then Latex said that it needed a Fuel Tracking function as well. </p>
                            <p>It’s not an unreasonable ask. After all, any construction company that uses as much heavy machinery as latex does is also spending a truckload on fuel. Using an app such as this to track the use of fuel is logical. So we started looking into the solution. </p>
                            <p>The problem was we were already well into the six-month project window, and the time it would take to design, develop, and test an entirely new functionality was cutting significantly into the time we spent perfecting the Progress Reports. So we went to Latex and we told them the truth (if not an outright ultimatum): If we add this to the scope of the project, the Progress Reports will suffer. </p>
                            <p>We can’t afford to push back the deadline, and we never push back deadlines. We need to push back the scope. That isn’t to say that Urbity couldn’t create the fuel tracking functionality later, but it shouldn’t be done now. Basically, we hoped Latex would see the difference between what they wanted, and what they needed. Thankfully, they did.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">
                    <div className="grid mob-wrap col-2">
                        <Block modifiers="secondary-d">
                            <img className="screenshot" src="../../images/work/latex/fuel-tracking-dashboard.jpg" width="100%" />
                        </Block>

                        <Block modifiers="secondary-d">
                            <img className="screenshot" src="../../images/work/latex/indiv-signature.jpg" width="100%" />
                        </Block>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <blockquote>“We had seen the work Urbity did for one of our contractor companies and we were intrigued, but didn’t necessarily have high hopes considering the deadline we were working under. Brian didn’t see any issue getting the thing done, and they got the thing done.”<div className="by"><h3>Test</h3><h4>Test</h4></div></blockquote>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content column">

                    <Block modifiers="image-tri secondary-d">
                        <img className="screenshot" src="../../images/work/latex/mobile-map-report.png" width="100%" />
                    </Block>

                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <p className="big">We would never say that extra features aren’t worth pursuing, but sometimes they are worth delaying. The Latex app system demonstrated how effective the ground-level features were by themselves on the new project. Dedicating time to develop the extras, instead of rushing them right off the line, would pay dividends in the long run. </p>
                        </div>
                        <div className="step">
                            <h5>First impression is everything</h5>
                            <p>This was a big project, with a big budget, and it was only going to get bigger if Latex had its way. Again, there is a lot that goes on during any given pipeline construction process, and the peak function of the app was to collect all of these going-ons into a simple Progress Report for the company to review. That simple report was the sum of many additional complex tasks, however. We needed to create separate reports for all of the users in the field, which in turn needed to accept manual data entry while ingesting geographic and weather information, and also connecting to the user’s phone camera. All of this information needed to go to the right place and be organized into a digestible report. It was one of the most involved projects we’ve ever committed to, already. </p>
                            <p>And then Latex said that it needed a Fuel Tracking function as well.</p>
                            <p>It’s not an unreasonable ask. After all, any construction company that uses as much heavy machinery as Latex does is also spending a truckload on fuel. Using an app such as this to track the use of fuel is logical. So we started looking into the solution.</p>
                            <p>The problem was we were already well into the six-month project window, and the time it would take to design, develop, and test an entirely new functionality was cutting significantly into the time we spent perfecting the Progress Reports. So we went to Latex and we told them the truth (if not an outright ultimatum): If we add this to the scope of the project, the Progress Reports will suffer. We can’t afford to push back the deadline, and we never push back deadlines. We need to push back the scope. That isn’t to say that Urbity couldn’t create the fuel tracking functionality later, but it shouldn’t be done now.</p>
                            <p>Basically, we hoped Latex would see the difference between what they wanted, and what they needed. Thankfully, they did.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content column">
                    <Block modifiers="secondary-a no-pad">
                        <img className="screenshot" src="../../images/work/latex/mockup-board.png" width="100%" />
                    </Block>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            
                        </div>
                        <div>
                            <p>We finished the apps in time for the company to break ground on their big project, and we continued to add new features in the months to come.Meanwhile, the Progress Reports had an instantaneous impact on Latex Construction’s productivity. </p>
                            <p>As crews completed sections of the pipeline, the big bosses were able to track the project almost to the minute. This allowed them to plan accordingly, improving efficiency and preventing shortages. Moving forward, they were able to provide more accurate quotes for future projects, especially after the addition of the Fuel Tracking function. </p>
                            <p>Latex couldn’t push back their deadline, but they could push back their scope. Once Urbity helped them identify the most effective strategy, everything else fell into place in good time.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="values bright">
                <div className="content">
                    <div className="description">
                        <h2>What we learned</h2>
                        <p className="big">What you can do is important, but this project helped us realize that what we couldn’t do made a difference to the client’s satisfaction. </p>
                    </div>
                    <div className="column card-view">
                        <div className="card">
                            <h5>Timeline > Scope</h5>
                            <p>Long-term projects inspire agencies to extend deadlines. There's a reason why "dead" is built into "deadline." Appreciating urgency is essential. </p>
                        </div>

                        <div className="card">
                            <h5>Saying No Matters</h5>
                            <p>The client had a lot of viable ideas for the project. Too many. If we didn't say "no" for the time being, the more urgent needs would be impacted. </p>
                        </div>

                        <div className="card">
                            <h5>Don't Try Impossible</h5>
                            <p>The worst we could have done was pretend we could pull off the unthinkable. Pretending otherwise would have left the entire project dead in the water.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-to-action secondary-b">
                <div className="content extra column">
                    <div className="header space-between">
                        <div className="title">
                        <h4>Laying it Bare</h4>
                        <h3>See how we work in our<br/>epic inter-company tales.</h3>
                        </div>
                        <Link to="/stories" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>Check it out</span></Link>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
)

export default LatexPage;