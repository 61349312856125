import React from 'react'
import PropTypes from 'prop-types'

const Block = ({ children, style, modifiers }) => {

    return (
        <div className={`block-container ${modifiers}`} style={ style }>
            <div className="wrapper">
                <div className="container">
                    { children }
                </div>
            </div>
        </div>
    )
}

Block.propTypes = {
    children: PropTypes.node,
    modifiers: PropTypes.string,
    style: PropTypes.object
}

Block.defaultProps = {
    children: null,
    modifiers: "",
    style: {}
}

export default Block