import React  from 'react';
import { Router } from '@reach/router'

import WorkPage from './work'
import LatexPage from './latex'
import OSUPage from './osu'
import SurgerePage from './surgere'

const WorkRoute = () => (
    <Router basepath="/work">
        <WorkPage path="/" />
        <LatexPage path="/latex" />
        <OSUPage path="/osu" />
        <SurgerePage path="/surgere" />
    </Router>
)

export default WorkRoute;