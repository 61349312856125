import React from "react"
import { Link } from 'gatsby'

import Layout from '../../components/layout'
import Block from '../../components/block'

const SurgerePage = () => (
    <Layout colorTheme="white" bgColorTheme="secondary-a">
        <main id="work">
            <section className="hero secondary-a no-bottom-pad" style={{ overflow: 'hidden'}}>
                <div className="content-break">
                    <div className="breakout row" style={{ marginRight: "4rem", alignItems: 'flex-end'  }}>
                        <div className="image-responsive">
                            <div className="wrapper" style={{ paddingTop: '70%' }}>
                                <div className="image" style={{ left: -4, backgroundImage: "url('../../images/work/surgere/dashboard-browser.png')" }} />
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="tagline column" style={{ marginTop: "2rem", marginBottom: "2rem"}}>
                            <h5 className="subtext">Surgere</h5>
                            <h1 className="ex">Get it done now.<br/>Your client will thank you</h1>
                            <a className="button inverse hide-mobile icon"><i className="fas fa-chevron-down" /></a>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work bright">
                <div className="content" style={{ maxWidth: 800 }}>
                    <div className="product breakout row">
                        <div className="info column">
                            <div>Strategy</div>
                            <div>UI Design</div>
                            <div>React</div>
                            <div>Mobile App</div>
                        </div>
                        <div className="excerpt">
                            <h4>The Premise</h4>
                            <p className="big">Big manufacturers have millions of containers, and spend millions tracking them. Surgere develops hardware and software to improve those problems through tagging. Surgere would send tag orders to a third-party printer. Surgere's spreadsheet system was causing them pains. They wanted a tag-ordering system that made life easier for both clients and the Surgere employees.</p>
                            <p className="big">This is more complex than a simple e-commerce site; buying RFID tags is based on Purchase Orders, and those P.O.s could be broken down by line item, which themselves could be split up by quantity. Those single orders, confusing by themselves, could be split into deliveries to multiple locations. It was a tough job...and that was before the entire project changed midway through.</p> 
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-bottom-pad">
                <div className="content extra">
                    <div className="image-responsive ratio-1-2 round">
                        <div className="wrapper">
                            <div className="image" style={{ backgroundImage: "url('../../images/work/surgere/auto-line.jpg')" }} />
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <h4 className="upper">The Solution</h4>
                            <p className="big">What happens when a big project becomes a big project with a new, scary deadline? You buckle down and make some big decisions. In Urbity's case, those decisions would grow into our business model moving forward. </p>
                        </div>
                        <div className="step">
                            <h5>Steady as she goes</h5>
                            <p>This project was huge. Surgere handled millions of tags for some of the world’s biggest manufacturers. So yeah...a little bit of pressure. But at least we had 5 months to figure it out.</p>
                            <p>So we took a deep breath and got to work. We went about our process, designing and developing and keeping an eye on the clock. Surgere handled our requests, and didn’t put a gun to our head. Everybody was comfortable. Until they weren’t.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">
                    <div className="grid mob-wrap col-2">
                        <Block modifiers="secondary-a">
                            <img className="screenshot" src="../../images/work/surgere/wireframe.png" width="100%" />
                        </Block>

                        <Block modifiers="image-cut secondary-a">
                            <img className="screenshot" src="../../images/work/surgere/style-guide.jpg" width="100%" style={{ marginTop: "10%" }} />
                        </Block>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <p className="big">When software gets done quickly, everybody is happy. We aim to make you happy, which occurs because your boss is happy. In this case, the boss was getting some feedback from one of Surgere's biggest clients, and everyone realized the client's happiness would make everybody's life easier.</p>
                        </div>
                        <div className="step">
                            <h5>Needed: A dramatic solution</h5>
                            <p>The first problem wasn’t a problem per se: Surgere decided to bring their tag printing in-house, which doubled the size of the project. We could work with that.</p>
                            <p>But it turns out that one of the company’s biggest clients was irritated the app wasn’t available yet. They gave a stern message to the CEO of Surgere and the message worked its way down the grapevine, until it ended up back on our plate. When the customer is unhappy, ultimately everyone ends up unhappy.</p>
                            <p>We couldn’t say no, but we had to figure out some way to make it work. The full scope of the project simply couldn’t be done in a few weeks. So we sat down with Surgere and hashed out a compromise: We would finish the web app, and focus on mobile later. It may create a slight inconvenience for the time being, but it allowed for the reporting feature that Surgere needed.</p>
                            <p>Cutting nearly half the project didn’t make the new deadline much easier, however. We needed more hours in the day. One guy, exhausted, had an idea crazy enough to work: What if, instead of them working on a totally separate project, we just handed off the baton on this project so our Romanian team could jump in smoothly from where we left off? And then repeat in the morning? </p>
                            <p>Our workdays overlapped just enough that we could converse on what the other team had done, and pick up where they had left off. It was almost as if we were working 24 hours a day (we were both pulling 12-hour shifts).</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">
                    <Block modifiers="image-tri secondary-a">
                        <img className="screenshot" src="../../images/work/surgere/mock-1.png" width="45%" />
                        <div style={{ width: "10%", display: 'inline-block', justifyContent: 'center' }}>
                            <img className="screenshot" src="../../images/work/surgere/mock-2.png" width="500%" style={{ marginBottom: '-15%', zIndex: 4, left: "-200%", position: 'relative', boxShadow: '0 20px 10px 10px rgba(0, 0, 0, 0.15)' }} />
                        </div>
                        <img className="screenshot" src="../../images/work/surgere/mock-3.png" width="45%" />
                    </Block>
                </div>
            </section>

            <section className="work white">
                <div className="content column">
                    <blockquote>“Urbity came to our facility, listened to our team and stakeholders, and diagramed the process. They had the implementation plan the next day and it was executed over the next couple weeks.  Urbity worked long days, nights and weekends to alleviate my challenges.”<div className="by"><h3 className="ex">Rusty Coleman</h3><h4>Vice President of Digital Transformation</h4></div></blockquote>
                </div>
            </section>

            <section className="work white no-pad">
                <div className="content extra column">

                    <Block modifiers="image-cut secondary-a">
                        <img className="screenshot" src="../../images/screenshot.jpg" width="100%" />
                    </Block>

                </div>
            </section>

            <section className="work white no-bottom-pad">
                <div className="content column">
                    <div className="grid mob-wrap in-pad" style={{ gridTemplateColumns: '3fr 4fr' }}>
                        <div className="column">
                            <p className="big">The project demonstrated what Urbity could do in a limited amount of time. Of course, we would rather have done it across a longer period of time. So we found a way to apply what we had learned to the Urbity process for a happy middle ground between speed and insanity. </p>
                        </div>
                        <div className="step">
                            <h5>The chosen path</h5>
                            <p>At the end of the project, everything worked out. Surgere’s client was thrilled with what we managed to churn out in those few weeks. And since the client was happy, the CEO was happy, and he passed those compliments along to the staff responsible. They, in turn, heaped praise upon Urbity. It felt great, but our bodies felt exhausted.</p>
                            <p>We had shown what we could do in two weeks. But was it worth the cost? There’s no way we could continue like this week-in and week-out. So we stepped back and looked at what worked, and how it could be adapted to a more sane schedule.</p>
                            <p>Ultimately we had succeeded because we took the time to sit down and create a list of fundamentals, and then stuck to that plan for the next 2 weeks. Theoretically, the same idea would work across 3 weeks, or 5 weeks. We finally decided that 8 weeks would be the ideal deadline for finishing a piece of software, without breaking our own wills. </p>
                            <p>This project also led us to change the dynamic of our relationship with the Romanian team, always working in sync on the same projects for maximum efficiency. In many ways, we owe Surgere for holding our feet to the fire. It was a rough 2 weeks...but it shifted the way we see development.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="work white">
                <div className="content extra column">
                    <div className="grid mob-wrap col-2">

                        <Block modifiers="ratio-3-4 secondary-a">
                            <img className="screenshot" src="../../images/work/surgere/mobile.jpg" width="100%" />
                        </Block>

                        <div className="image-responsive ratio-3-4 round">
                            <div className="wrapper">
                                <div className="image" style={{ backgroundImage: "url('../../images/work/surgere/urbity8.jpg')", backgroundPosition: 'top right' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="values bright">
                <div className="content">
                    <div className="description">
                        <h2>What we learned</h2>
                        <p className="big">We learned that even seemingly impossible deadlines can be met. But we also learned we didn't want to be in that position, so we adapted the process we discovered into a more reasonable timeline for future use.</p>
                    </div>
                    <div className="column card-view">
                        <div className="card">
                            <h5>Prioritize now, act later</h5>
                            <p>It's definitely possible to overthink a project, and all those extra details can quickly derail a solid plan. We learned it’s better to identify everything that you need, get it done, and then add everything else. It keeps your team from getting distracted, and keeps deadlines in sight.</p>
                        </div>

                        <div className="card">
                            <h5>If it ain't broke...</h5>
                            <p>Our entire business model! We can’t reasonably work nonstop for 24 hours, but when we turn the project over to our European teammates at the end of the day, they can keep the train running. That’s a plan for maximum efficiency.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="call-to-action secondary-b">
                <div className="content extra column">
                    <div className="header space-between">
                        <div className="title">
                        <h4>Fast Software Development</h4>
                        <h3>Bringing new tech<br/>to established industry</h3>
                        </div>
                        <Link to="/work/latex" className="button inverse mob-icon"><i className="fas fa-chevron-right" /><span>Read more</span></Link>
                    </div>
                </div>
            </section>
        </main>
    </Layout>
)

export default SurgerePage;